import React from 'react'

/**
 * Styles must be imported before App. Otherwise tailwind will override button type="submit" etc. Workaround found here:
 * https://stackoverflow.com/questions/70387965/tailwindcss-3-0-upgrade-overriding-button-styles Issue is not solved yet in
 * January 2023. See more: https://github.com/tailwindlabs/tailwindcss/discussions/7049 See more:
 * https://github.com/facebook/create-react-app/pull/11926
 */
import '@styles/global.css'

import App, { AppProviders } from 'app'
import 'focus-visible'
import { createRoot } from 'react-dom/client'

import { initMocks } from '@test/msw'

import reportWebVitals from './reportWebVitals'

initMocks()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <AppProviders>
    <App />
  </AppProviders>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
