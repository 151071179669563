import i18n from 'i18next'
import type { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationDA from '@i18n/locales/da.json'
import translationEN from '@i18n/locales/en.json'
import { Language } from '@typings'

const resources: Resource = {
  [Language.Danish]: {
    translation: translationDA,
  },
  [Language.English]: {
    translation: translationEN,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,

    lng: window.Cypress ? Language.Danish : undefined,
    fallbackLng: Language.Danish,
    supportedLngs: [Language.Danish, Language.English],
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  })

export default i18n
