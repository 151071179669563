import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

interface State {
  selectedFrontpageProject?: number
  rentalAgreementsIsCollapsed: boolean
}

const initialState: State = {
  selectedFrontpageProject: undefined,
  rentalAgreementsIsCollapsed: false,
}

const myGsvFrontpageSlice = createSlice({
  name: 'myGsvFrontpage',
  initialState,
  reducers: {
    setFrontpageProject: (state, { payload }: PayloadAction<number>) => {
      state.selectedFrontpageProject = payload
    },
    toggleCollapseRentalAgreements: (state) => {
      state.rentalAgreementsIsCollapsed = !state.rentalAgreementsIsCollapsed
    },
    /** Reset the current state of frontpage project to the initial state. */
    resetFrontpageProject: (state) => {
      state.selectedFrontpageProject = initialState.selectedFrontpageProject
    },
    /** Reset the current state to the initial state. */
    reset: () => initialState,
  },
})

// Actions
export const { reset, setFrontpageProject, toggleCollapseRentalAgreements, resetFrontpageProject } = myGsvFrontpageSlice.actions

/**
 * Hook for selecting the MyGsvFrontpage state
 *
 * @returns The MyGsvFrontpage state
 */
export const useMyGsvFrontpageSlice = (): State => {
  return useAppSelector((state: RootState) => state.myGsvFrontpage)
}

export default myGsvFrontpageSlice.reducer
