import { Endpoint, usageApi } from '@services/usage/api'
import type * as ApiType from '@services/usage/types'
import { createSearchParams } from '@services/util'

export const shedsApi = usageApi.injectEndpoints({
  endpoints: (build) => ({
    getSheds: build.query<ApiType.GetShedsResponse, ApiType.GetShedsParams>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.Sheds}/status-for-period?${params.toString()}`
        }

        return `${Endpoint.Sheds}/status-for-period`
      },
      providesTags: ['Sheds'],
    }),

    getShedGps: build.query<ApiType.GetShedGpsResponse, ApiType.GetShedGpsParams>({
      query: ({ equipment_no }) => {
        return `${Endpoint.Sheds}/${equipment_no}/gps-track`
      },
      extraOptions: { maxRetries: 1 },
    }),
  }),
})

export const { useGetShedsQuery, usePrefetch: usePrefetchSheds, useLazyGetShedGpsQuery } = shedsApi
