import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { z } from 'zod'

import { projectsApi } from '@services/dashboard/entities/project/project.api'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

const sortDirectionSchema = z.union([z.literal('asc'), z.literal('desc')])

const sortProjectColumnsNamesSchema = z.union([
  z.literal('name'),
  z.literal('start_date'),
  z.literal('end_date'),
  z.literal('economy'),
  z.literal('co2_target'),
  z.literal('co2_usage.value'),
  z.literal('co2_usage.difference_percentage'),
])

const searchSchema = z.object({
  searchQuery: z.string().optional(),
  sort: z
    .object({
      columnName: sortProjectColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
})

export type SearchValues = z.infer<typeof searchSchema>
export type SortProjectColumnsNames = z.infer<typeof sortProjectColumnsNamesSchema>

interface State {
  currentPage: number
  perPage: number
  lastPage?: number
  loading?: boolean
  search?: SearchValues
}

const initialState: State = {
  currentPage: 1,
  perPage: 10,
  lastPage: undefined,
  loading: false,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    /** Increment the current page on the overview. */
    incrementPage: (state) => {
      state.currentPage += 1
    },

    /** Decrement the current page on the overview. */
    decrementPage: (state) => {
      state.currentPage -= 1
    },

    /** Set the current page on the overview. */
    setPage: (state, { payload }: PayloadAction<State['currentPage']>) => {
      state.currentPage = payload
    },

    /** Set the per page value on the overview. */
    setPerPage: (state, { payload }: PayloadAction<State['perPage']>) => {
      state.perPage = payload
      state.currentPage = 1
    },

    /** Set the search to the payload value and reset the current page. */
    setSearch: (state, { payload }: PayloadAction<SearchValues>) => {
      state.search = merge(state.search, payload)
      state.currentPage = initialState.currentPage
    },

    /** Reset the current state to the initial state. */
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which shouldn't be
       * visible to the user.
       *
       * @see {getProjects} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(projectsApi.endpoints.getProjects.matchPending, (state, { meta }) => {
        if (state.currentPage === meta.arg.originalArgs?.page) {
          state.loading = true
        }
      })
      /** Add lastPage meta to state when the {@link getProjects} query fulfills */
      .addMatcher(projectsApi.endpoints.getProjects.matchFulfilled, (state, { payload }) => {
        state.lastPage = payload.meta?.last_page ?? 1
        state.loading = false
      })
  },
})

// Actions
export const { incrementPage, decrementPage, setSearch, setPerPage, setPage, reset } = projectsSlice.actions

/**
 * Hook for selecting the Projects state
 *
 * @returns The Projects state
 */
export const useProjectsState = (): State => {
  return useAppSelector((state: RootState) => state.projects)
}

export default projectsSlice.reducer
