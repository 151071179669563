import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { dashboardApi } from 'services/dashboard'
import { userApi } from 'services/user'

import { usageApi } from '@services/usage'

import authReducer from '@features/auth/auth.slice'
import economyReducer from '@features/economy/economy.slice'
import energyDashboardReducer from '@features/energy-dashboard/energy-dashboard.slice'
import myGsvFrontpageReducer from '@features/my-gsv-frontpage/my-gsv-frontpage.slice'
import projectsReducer from '@features/projects/projects.slice'
import rentalAgreementsReducer from '@features/rental-agreements/rentalAgreements.slice'
import uiReducer from '@features/ui/ui.slice'
import userManagementReducer from '@features/user-management/userManagement.slice'

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['auth'],
}

const uiPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['sidebarIsCollapsed'],
}

const myGsvFrontpagePersistConfig = {
  key: 'myGsvFrontpage',
  storage,
  whitelist: ['rentalAgreementsIsCollapsed', 'selectedFrontpageProject'],
}

const energyDashboardPersistConfig = {
  key: 'energyDashboard',
  storage,
  whitelist: ['showServicePurchaseInfo', 'showClimateControlInfo'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  myGsvFrontpage: persistReducer(myGsvFrontpagePersistConfig, myGsvFrontpageReducer),
  rentalAgreements: rentalAgreementsReducer,
  economy: economyReducer,
  projects: projectsReducer,
  userManagement: userManagementReducer,
  energyDashboard: persistReducer(energyDashboardPersistConfig, energyDashboardReducer),
  ui: persistReducer(uiPersistConfig, uiReducer),
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [usageApi.reducerPath]: usageApi.reducer,
})

export default persistReducer(persistConfig, rootReducer)
