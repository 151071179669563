import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '@store'
import { useAppSelector } from '@store/hooks'

import { Role } from '@typings'

interface AuthState {
  token: string | null
  userRole: Role | null
  dataPortalAccess: boolean
}

const initialState: AuthState = {
  token: null,
  userRole: null,
  dataPortalAccess: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Set the current token. This is also used to determine if the user is logged in or not.
     *
     * If the token is not set, the user will be shown the unauthenticated app (guest).
     */
    setToken: (state, { payload }: PayloadAction<AuthState['token']>) => {
      state.token = payload
    },
    /** Set user role (User, Admin, SalesAdmin) */
    setRole: (state, { payload }: PayloadAction<AuthState['userRole']>) => {
      state.userRole = payload
    },
    /** Set user access to Data Portal */
    setDataPortalAccess: (state, { payload }: PayloadAction<AuthState['dataPortalAccess']>) => {
      state.dataPortalAccess = payload
    },
    logout: () => initialState,
  },
})

// Actions
export const { setToken, logout, setRole, setDataPortalAccess } = authSlice.actions

/**
 * Hook for selecting the Auth state
 *
 * @returns The Auth state
 */
export const useAuthState = (): AuthState => {
  return useAppSelector((state: RootState) => state.auth)
}

export default authSlice.reducer
