import isNil from 'lodash/isNil'

type Params = Record<string, any> | void

type Options = {
  baseSearchParams?: URLSearchParams
}

export function createSearchParams(searchParams: Params, options?: Options): URLSearchParams
export function createSearchParams(searchParams: URLSearchParams): URLSearchParams
export function createSearchParams(queryParams: Params): URLSearchParams
/**
 * Creates a URLSearchParams object from query params of type `T`
 *
 * @param {object} queryParams
 * @returns {URLSearchParams}
 */
export function createSearchParams<T extends Params>(queryParams: T, options?: Options): URLSearchParams {
  const searchParams = options?.baseSearchParams ?? new URLSearchParams()

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((value) => {
          const valueString = value?.toString()

          if (valueString) {
            if (key.includes('[]')) {
              searchParams.append(`${key}`, valueString)
            } else {
              searchParams.append(`${key}[]`, valueString)
            }
          }
        })
      } else {
        const valueString = value?.toString()

        if (!isNil(valueString)) {
          searchParams.set(key, valueString)
        } else {
          searchParams.delete(key)
        }
      }
    })
  }

  return searchParams
}
