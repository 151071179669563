type Id = string | number

/** Function curry that handles invalidation of tag types. */
export function prepareTagTypes<T extends readonly string[]>() {
  return <TagTypes extends T[number]>(type: TagTypes, ids?: Id | Id[]) => {
    if (Array.isArray(ids)) {
      return [
        ...ids.map((id) => ({
          type,
          id,
        })),
        { type, id: 'LIST' },
      ]
    }

    return ids
      ? [
          { type, id: ids },
          { type, id: 'LIST' },
        ]
      : [{ type, id: 'LIST' }]
  }
}
