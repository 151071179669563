import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import subDays from 'date-fns/subDays'
import merge from 'lodash/merge'
import { z } from 'zod'

import { usageApi } from '@services/dashboard/entities/usage/usage.api'
import { Shed } from '@services/usage'
import { shedsApi } from '@services/usage/entities/sheds/sheds.api'

import { useAppSelector } from '@store/hooks'
import type { RootState } from '@store/index'

const tabSchema = z.enum(['overview', 'material', 'reporting', 'shed'])

const sortDirectionSchema = z.enum(['asc', 'desc'])

const sortMaterialColumnsNamesSchema = z.enum(['title', 'co2', 'fuel', 'idle_hours', 'usage_hours', 'case_number'])

const sortShedsColumnsNamesSchema = z.enum([
  'name',
  'case_number',
  'min_temperature',
  'max_temperature',
  'temperature_average',
  'power_usage_total',
  'night_sync_status',
])

const materialsSearchSchema = z.object({
  searchQuery: z.string().optional(),
  //   owner: z.union([z.literal('gsv'), z.literal('kp'), z.literal('all')]),
  projectId: z.number().optional(),
  startDate: z.string(),
  endDate: z.string(),
  compareVariant: z
    .union([z.literal('preceding'), z.literal('lastYear')])
    .optional()
    .nullable(),
  sort: z
    .object({
      columnName: sortMaterialColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
})

const shedsSearchSchema = z.object({
  projectId: z.number().optional(),
  startDate: z.string(),
  endDate: z.string(),
  sort: z
    .object({
      columnName: sortShedsColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
  compareVariant: z
    .union([z.literal('preceding'), z.literal('lastYear')])
    .optional()
    .nullable(),
})

const climateControlSearchSchema = z.object({
  sort: z
    .object({
      columnName: sortShedsColumnsNamesSchema,
      direction: sortDirectionSchema,
    })
    .optional()
    .nullable(),
})
const reportingSchema = z.enum(['materials', 'sheds'])

export type TabType = z.infer<typeof tabSchema>

export type MaterialsSearchValues = z.infer<typeof materialsSearchSchema>
export type HighlightedMaterialsSearchValues = Omit<MaterialsSearchValues, 'searchQuery'>
export type OverviewSearchValues = Omit<HighlightedMaterialsSearchValues, 'sort'>
export type ShedsSearchValues = z.infer<typeof shedsSearchSchema>
export type HighlightedShedsSearchValues = z.infer<typeof shedsSearchSchema>
export type ClimateControlSearchValues = z.infer<typeof climateControlSearchSchema>

export type SortMaterialColumnsNames = z.infer<typeof sortMaterialColumnsNamesSchema>
export type SortShedsColumnsNames = z.infer<typeof sortShedsColumnsNamesSchema>

export type ReportingType = z.infer<typeof reportingSchema>

interface State {
  activeTab: TabType
  overview: {
    search: OverviewSearchValues
  }
  highlightedMaterials: {
    search: HighlightedMaterialsSearchValues
  }
  highlightedSheds: {
    search: HighlightedShedsSearchValues
  }
  materials: {
    currentPage: number
    perPage: number
    lastPage?: number
    loading?: boolean
    search: MaterialsSearchValues
  }
  sheds: {
    currentPage: number
    perPage: number
    lastPage?: number
    loading?: boolean
    search: ShedsSearchValues
  }
  reportingType: ReportingType
  shedsClimateControl: {
    currentPage: number
    perPage: number
    lastPage?: number
    loading?: boolean
    search: ClimateControlSearchValues
    selectedSheds: Shed[]
  }
  showServicePurchaseInfo: boolean
  showClimateControlInfo: boolean
}

const initialState: State = {
  activeTab: 'overview',
  overview: {
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  highlightedMaterials: {
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  highlightedSheds: {
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  materials: {
    currentPage: 1,
    perPage: 10,
    lastPage: undefined,
    loading: false,
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  sheds: {
    currentPage: 1,
    perPage: 10,
    lastPage: undefined,
    loading: false,
    search: {
      startDate: subDays(new Date(), 30).toString(),
      endDate: subDays(new Date(), 1).toString(),
      compareVariant: 'preceding',
    },
  },
  reportingType: 'materials',
  shedsClimateControl: {
    currentPage: 1,
    perPage: 10,
    lastPage: undefined,
    loading: false,
    search: {},
    selectedSheds: [],
  },
  showServicePurchaseInfo: true,
  showClimateControlInfo: true,
}

const energyDashboardSlice = createSlice({
  name: 'energyDashboard',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<TabType>) => {
      state.activeTab = action.payload
    },
    /** Merge the Overview search values into the state. */
    setOverviewSearch: (state, { payload }: PayloadAction<OverviewSearchValues>) => {
      state.overview.search = merge(state.overview.search, payload)
    },

    /** Merge the Highlighted Materials search values into the state. */
    setHighlightedMaterialsSearch: (state, { payload }: PayloadAction<Partial<HighlightedMaterialsSearchValues>>) => {
      state.highlightedMaterials.search = merge(state.highlightedMaterials.search, payload)
    },

    /** Merge the Highlighted Sheds search values into the state. */
    setHighlightedShedsSearch: (state, { payload }: PayloadAction<Partial<HighlightedShedsSearchValues>>) => {
      state.highlightedSheds.search = merge(state.highlightedSheds.search, payload)
    },

    /** Increment the current page on the Materials tab. */
    incrementMaterialsPage: (state) => {
      state.materials.currentPage += 1
    },

    /** Decrement the current page on the Materials tab. */
    decrementMaterialsPage: (state) => {
      state.materials.currentPage -= 1
    },

    /** Set the current page on the Materials tab. */
    setMaterialsPage: (state, { payload }: PayloadAction<State['materials']['currentPage']>) => {
      state.materials.currentPage = payload
    },

    /** Set the per page value on the Materials tab. */
    setMaterialsPerPage: (state, { payload }: PayloadAction<State['materials']['perPage']>) => {
      state.materials.perPage = payload
      state.materials.currentPage = 1
    },

    /** Merge the Material search values into the state and reset the current page. */
    setMaterialsSearch: (state, { payload }: PayloadAction<Partial<MaterialsSearchValues>>) => {
      state.materials.search = merge(state.materials.search, payload)
      state.materials.currentPage = initialState.materials.currentPage
    },

    /** Increment the current page on the Sheds tab. */
    incrementShedsPage: (state) => {
      state.sheds.currentPage += 1
    },

    /** Decrement the current page on the Sheds tab. */
    decrementShedsPage: (state) => {
      state.sheds.currentPage -= 1
    },

    /** Set the current page on the Sheds tab. */
    setShedsPage: (state, { payload }: PayloadAction<State['sheds']['currentPage']>) => {
      state.sheds.currentPage = payload
    },

    /** Set the per page value on the Sheds tab. */
    setShedsPerPage: (state, { payload }: PayloadAction<State['sheds']['perPage']>) => {
      state.sheds.perPage = payload
      state.sheds.currentPage = 1
    },

    /** Merge the Sheds search values into the state and reset the current page. */
    setShedsSearch: (state, { payload }: PayloadAction<Partial<ShedsSearchValues>>) => {
      state.sheds.search = merge(state.sheds.search, payload)
      state.sheds.currentPage = initialState.sheds.currentPage
    },

    /** Set the type for reporting page. */
    setReportingType: (state, action: PayloadAction<ReportingType>) => {
      state.reportingType = action.payload
    },

    /** Add shed(s) to the selectedSheds for Sheds Climate Control. */
    addShedsClimateControlSelectedSheds: (state, { payload }: PayloadAction<Shed[]>) => {
      payload.forEach((shed) => {
        if (!state.shedsClimateControl.selectedSheds.some((selectedShed) => selectedShed.equipment_no === shed.equipment_no)) {
          state.shedsClimateControl.selectedSheds.push(shed)
        }
      })
    },

    /** Remove shed(s) to the selectedSheds for Sheds Climate Control. */
    removeShedsClimateControlSelectedSheds: (state, { payload }: PayloadAction<Shed[]>) => {
      const equipmentNumbersToRemove = new Set(payload.map((shed) => shed.equipment_no))
      state.shedsClimateControl.selectedSheds = state.shedsClimateControl.selectedSheds.filter(
        (shed) => !equipmentNumbersToRemove.has(shed.equipment_no),
      )
    },

    /** Reset selectedSheds for Sheds Climate Control. */
    resetShedsClimateControlSelectedSheds: (state) => {
      state.shedsClimateControl.selectedSheds = initialState.shedsClimateControl.selectedSheds
    },

    /** Set the Sheds Climate Control sort values and reset the current page. */
    setShedsClimateControlSearch: (state, { payload }: PayloadAction<Partial<ClimateControlSearchValues>>) => {
      state.shedsClimateControl.search = merge(state.shedsClimateControl.search, payload)
      state.shedsClimateControl.currentPage = initialState.shedsClimateControl.currentPage
    },

    /** Increment the current page on the Sheds Climate Control. */
    incrementShedsClimateControlPage: (state) => {
      state.shedsClimateControl.currentPage += 1
    },

    /** Decrement the current page on the Sheds Climate Control. */
    decrementShedsClimateControlPage: (state) => {
      state.shedsClimateControl.currentPage -= 1
    },

    /** Set the current page on the Sheds Climate Control. */
    setShedsClimateControlPage: (state, { payload }: PayloadAction<State['shedsClimateControl']['currentPage']>) => {
      state.shedsClimateControl.currentPage = payload
    },

    /** Set the per page value on the Sheds Climate Control. */
    setShedsClimateControlPerPage: (state, { payload }: PayloadAction<State['shedsClimateControl']['perPage']>) => {
      state.shedsClimateControl.perPage = payload
      state.shedsClimateControl.currentPage = 1
    },

    /** Toggle the display of service purchase information on sheds page. */
    toggleShowServicePurchaseInfo: (state) => {
      state.showServicePurchaseInfo = !state.showServicePurchaseInfo
    },

    /** Toggle the display of climate control information on sheds page. */
    toggleShowClimateControlInfo: (state) => {
      state.showClimateControlInfo = !state.showClimateControlInfo
    },

    /** Reset the current state to the initial state. */
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which shouldn't be
       * visible to the user.
       *
       * @see {getUsageMaterials} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(usageApi.endpoints.getUsageMaterials.matchPending, (state, { meta }) => {
        if (state.materials.currentPage === meta.arg.originalArgs?.page) {
          state.materials.loading = true
        }
      })
      /** Add lastPage meta to state when the {@link getUsageMaterials} query fulfills */
      .addMatcher(usageApi.endpoints.getUsageMaterials.matchFulfilled, (state, { payload }) => {
        state.materials.lastPage = payload.meta?.last_page ?? 1
        state.materials.loading = false
      })

      /**
       * If the current page number (pagination) and the pending request (prefetching) page argument matches, set searching loader
       *
       * This is done because a search loader would be visible when prefetching the next page (pagination), which shouldn't be
       * visible to the user.
       *
       * @see {getSheds} endpoint for the page argument
       * @see {SearchOverview} component for the search loader
       */
      .addMatcher(shedsApi.endpoints.getSheds.matchPending, (state, { meta }) => {
        if (state.sheds.currentPage === meta.arg.originalArgs?.page) {
          state.sheds.loading = true
        }
      })
      /** Add lastPage meta to state when the {@link getProjects} query fulfills */
      .addMatcher(shedsApi.endpoints.getSheds.matchFulfilled, (state, { payload }) => {
        state.sheds.lastPage = payload.meta?.last_page ?? 1
        state.sheds.loading = false
      })
  },
})

// Actions
export const {
  setActiveTab,
  setOverviewSearch,
  setHighlightedMaterialsSearch,
  incrementMaterialsPage,
  decrementMaterialsPage,
  setMaterialsPage,
  setMaterialsPerPage,
  setMaterialsSearch,
  incrementShedsPage,
  decrementShedsPage,
  setShedsPerPage,
  setShedsPage,
  setShedsSearch,
  setHighlightedShedsSearch,
  setReportingType,
  addShedsClimateControlSelectedSheds,
  removeShedsClimateControlSelectedSheds,
  resetShedsClimateControlSelectedSheds,
  setShedsClimateControlSearch,
  incrementShedsClimateControlPage,
  decrementShedsClimateControlPage,
  setShedsClimateControlPage,
  setShedsClimateControlPerPage,
  toggleShowServicePurchaseInfo,
  toggleShowClimateControlInfo,
  reset,
} = energyDashboardSlice.actions

/**
 * Hook for selecting the Energy Dashboard state
 *
 * @returns The Energy Dashboard's state
 */
export const useEnergyDashboardState = (): State => {
  return useAppSelector((state: RootState) => state.energyDashboard)
}

export default energyDashboardSlice.reducer
