/** Environment variables */
export const env = {
  API_MOCKING: import.meta.env.VITE_API_MOCKING as string,
  AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE as string,
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN as string,
  DASHBOARD_SERVICE_BASE_URL: import.meta.env.VITE_DASHBOARD_SERVICE_BASE_URL as string,
  GOOGLE_MAPS_KEY: import.meta.env.VITE_GOOGLE_MAPS_KEY as string,
  NODE_ENV: import.meta.env.NODE_ENV,
  USER_SERVICE_BASE_URL: import.meta.env.VITE_USER_SERVICE_BASE_URL as string,
  USAGE_SERVICE_BASE_URL: import.meta.env.VITE_USAGE_SERVICE_BASE_URL as string,
  GSV_DK_LINK: import.meta.env.VITE_GSV_DK_LINK as string,
} as const

export const routeChangeDuration = 150

export const GSVMainPageDK = env.GSV_DK_LINK
export const GSVMainPageEN = `${env.GSV_DK_LINK}/en/`
export const ProductOverviewURLDK = `${env.GSV_DK_LINK}/produkter/`
export const ProductOverviewURLEN = `${env.GSV_DK_LINK}/en/produkter/`
export const GSVContactPageDK = `${env.GSV_DK_LINK}/gsv-afdelinger/`
export const GSVContactPageEN = `${env.GSV_DK_LINK}/en/gsv-afdelinger/`
