import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

import { prepareTagTypes } from '@services/util/prepareTagTypes'

import { RootState } from '@store'

import { env } from '@config'

export enum Endpoint {
  Sheds = '/sheds',
  ShedsElectricityOverview = '/sheds/electricity-meter',
  ShedsTemperatureOverview = '/sheds/temperature',
  ShedsClimateControl = '/sheds/climate-control',
}

const baseQuery = fetchBaseQuery({
  baseUrl: env.USAGE_SERVICE_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Content-Type', 'application/json')
    const token = (getState() as RootState).auth.token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
})

const tagTypes = ['Sheds', 'ShedsElectricityOverview', 'ShedsTemperatureOverview', 'ShedsClimateControl'] as const

export const tags = prepareTagTypes<typeof tagTypes>()

export const usageApi = createApi({
  reducerPath: 'usageApi',
  refetchOnReconnect: true,
  baseQuery: retry(baseQuery, { maxRetries: 2 }),
  tagTypes,
  endpoints: () => ({}),
})
