import { Endpoint, dashboardApi, tags } from '@services/dashboard'
import type * as ApiType from '@services/dashboard/types'
import { createSearchParams } from '@services/util'

export const userManagementApi = dashboardApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getUsers: build.query<ApiType.GetUsersResponse, ApiType.GetUsersParams | void>({
      query: (queryParams) => {
        const params = createSearchParams(queryParams)

        if (params.toString()) {
          return `${Endpoint.UserManagement}?${params.toString()}`
        }

        return Endpoint.UserManagement
      },
      providesTags: (result) => {
        return tags(
          'UserManagement',
          result?.data?.map((user) => user.id),
        )
      },
    }),

    getCompanies: build.query<ApiType.GetCompaniesSimpleResponse, void>({
      query: () => ({
        url: `${Endpoint.CompaniesSimple}`,
      }),
      providesTags: ['Companies'],
    }),

    getUser: build.query<ApiType.GetUserResponse, ApiType.GetUserParams>({
      query: (result) => ({
        url: `${Endpoint.UserManagement}/${result.id}`,
      }),
      providesTags: (result) => tags('UserManagement', result?.data?.id),
    }),

    updateUser: build.mutation<ApiType.UpdateUserResponse, ApiType.UpdateUserRequest & ApiType.UpdateUserParams>({
      query: ({ id, ...patch }) => ({
        url: `${Endpoint.UserManagement}/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: (result) => tags('UserManagement', result?.data?.id),
    }),

    createUser: build.mutation<ApiType.CreateUserResponse, ApiType.CreateUserRequest | void>({
      query: (body) => ({
        url: Endpoint.UserManagement,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: tags('UserManagement'),
    }),

    deleteUser: build.mutation<ApiType.DeleteUserResponse, ApiType.DeleteUserParams>({
      query: (result) => ({
        url: `${Endpoint.UserManagement}/${result.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => tags('UserManagement'),
    }),
  }),
})

export const {
  usePrefetch: usePrefetchUsers,
  useGetUsersQuery,
  useGetCompaniesQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userManagementApi
